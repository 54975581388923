@media screen and (min-width: 1024px) {
  .uploadsDocuments {
    width: 300px;
    height: 400px;
  }
}
.uploads {
  background: radial-gradient(
    90.16% 143.01% at 15.32% 21.04%,
    rgba(165, 255, 195, 0.2) 0%,
    rgba(110, 244, 161, 0.045) 75%,
    rgba(18, 54, 34, 0.2) 100%
  );
  background-blend-mode: overlay, normal;
  backdrop-filter: blur(7.793px);
  border-radius: 3.8965px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  overflow-x: auto;
  display: flex;
}
